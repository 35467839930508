import React, { useCallback, useEffect, useState } from "react";
import {
  View,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
  FlatList,
  Modal,
  SafeAreaView,
  useWindowDimensions,
  Pressable,
} from "react-native";
import Toast from "react-native-toast-message";
import { useNavigation, NavigationProp } from "@react-navigation/native";
import { RouteProp } from "@react-navigation/native";

import {
  BoxItemProps,
  DoctorRequest,
  ItemType,
  RootStackParamList,
} from "../utils/types";
import { createRequest, fetchRequests } from "../utils/services";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

type Props = {
  route: RouteProp<RootStackParamList, "Requests">;
};

const RequestsPage = ({ route }: Props) => {
  const { doctor, roomList, room, requestTypes } = route.params;
  const { name, id: doctorId } = doctor;
  const { t } = useTranslation();
  const languages = [
    { key: "English", value: "en" },
    { key: "Français", value: "fr" },
    { key: "عربي", value: "ar" },
    { key: "中文", value: "cn" },
    { key: "한국어", value: "kr" },
  ];

  const [selectedRequest, setSelectedRequest] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState("en"); // Default to English
  const [details, setDetails] = useState("");
  const [requests, setRequests] = useState<unknown[]>([]);
  const [isOptionModalVisible, setIsOptionModalVisible] = useState(false);
  const [closeAppModalVisible, setCloseAppModalVisible] = useState(false);

  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  const getRequests = async () => {
    if (doctorId) {
      fetchRequests(doctorId).then((requests) => {
        setRequests(
          requests.map((r: DoctorRequest) => {
            return { requestType: r.requestType, room: r.room };
          })
        );
      });
    }
  };

  useEffect(() => {
    getRequests();
    // const intervalId = setInterval(getRequests, 5000); // Fetch every 5 seconds
    // return () => clearInterval(intervalId); // Clean up on unmount
  }, []);

  const handleConfirm = async ({
    requestTypeName,
    option,
    requestType,
  }: {
    requestTypeName?: string;
    option?: string;
    requestType?: ItemType;
  }) => {
    setIsOptionModalVisible(false);
    const reqBody = {
      room: room.name,
      doctor: name,
      requestType: selectedRequest.name ?? requestTypeName,
      detail:
        selectedRequest.name === "Misc Assistance" ||
        requestTypeName === "Misc Assistance"
          ? details
          : option,
      icon: requestType.icon,
    };
    console.log(reqBody);
    const response = await createRequest(reqBody);
    if (response.status === 201 || response.status === 200) {
      Toast.show({
        type: "success",
        text1: `${t("Successfully requested")} ${
          selectedRequest.name ?? requestTypeName
        }`,
        text1Style: {
          fontSize: 15,
          fontWeight: "normal",
        },
      });
      setDetails("");
    } else {
      Toast.show({
        type: "error",
        text1: `${t("You have already requested")} ${t(
          selectedRequest.name
        )} for this room.`,
        text1Style: {
          fontSize: 15,
          fontWeight: "normal",
        },
      });
    }

    // await getRequests();

    // if (
    //   doctorId &&
    //   !requests.some(
    //     (r) => r.requestType === selectedRequest && r.room === room.name
    //   )
    // ) {
    //   const reqBody = {
    //     room: room.name,
    //     doctor: name,
    //     requestType: selectedRequest.name ?? requestTypeName,
    //     detail:
    //       selectedRequest.name === "Misc Assistance" ||
    //       requestTypeName === "Misc Assistance"
    //         ? details
    //         : option,
    //     color: selectedRequest.color,
    //     icon: selectedRequest.icon,
    //   };
    //   const response = await createRequest(reqBody);
    //   if (response.status === 201 || response.status === 200) {
    //     Toast.show({
    //       type: "success",
    //       text1: `${t("Successfully requested")} ${
    //         selectedRequest.name ?? requestTypeName
    //       }`,
    //       text1Style: {
    //         fontSize: 15,
    //         fontWeight: "normal",
    //       },
    //     });
    //     setDetails("");
    //   }
    // } else {
    //   Toast.show({
    //     type: "error",
    //     text1: `${t("You have already requested")} ${t(
    //       selectedRequest.name
    //     )} for this room.`,
    //     text1Style: {
    //       fontSize: 15,
    //       fontWeight: "normal",
    //     },
    //   });
    // }
  };

  useEffect(() => {
    i18next.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const { height, width } = useWindowDimensions();

  const Item = useCallback(
    ({ requestType, isHorizontal, isLast }: BoxItemProps) => (
      <TouchableOpacity
        onPress={async () => {
          setSelectedRequest(requestType); // idk why but this is not updating selectedRequest in time so I have to pass in name to handleConfirm
          if (requestType.name === "Misc Assistance") {
            setIsOptionModalVisible(true);
          } else {
            setIsOptionModalVisible(false);
            await handleConfirm({
              requestTypeName: requestType.name,
              requestType,
            });
          }
        }}
        className={`bg-[#ffffff] m-[2%] justify-center items-center border-black rounded-[20px] aspect-square flex-1 ${
          isLast && (isHorizontal ? "max-w-[21%]" : "max-w-[46%]") // a little less than 25 and 50 percent to account for gap between items
        }`}
      >
        <Image
          className="h-[60%] aspect-square self-center"
          source={requestType.icon}
        />
        <Text className="text-xl md:text-2xl lg:text-3xl xl:text-4xl items-center text-center text-black pt-2">
          {t(requestType?.name)}
        </Text>
      </TouchableOpacity>
    ),
    []
  );

  return (
    <SafeAreaView
      className="bg-[#ADCDF6] max-w-screen"
      style={{ height: "100%", maxHeight: "100dvh" }}
    >
      <View className="flex flex-row justify-between items-center py-2 px-4 bg-[#FFFFFB]">
        <Image
          className="w-[35px] h-[35px]"
          source={require("../assets/logo.png")}
        />
        <Text className="text-black text-lg">Henry Medical Centre</Text>
      </View>

      <TouchableOpacity
        className="bg-[#FFFFFB] rounded-[25px] items-center justify-center border-2 border-black m-4"
        onPress={() => navigation.navigate("Rooms", { doctor, roomList })}
      >
        <Text className="text-black text-2xl lg:text-[30px] py-2">
          {t("Back to Rooms")}
        </Text>
      </TouchableOpacity>

      <View className="flex-1 overflow-y-scroll">
        {height > width ? (
          <FlatList
            key="vertical"
            data={requestTypes}
            numColumns={2}
            renderItem={({ item, index }) => (
              <Item
                requestType={item}
                isLast={index + 1 > 2 * Math.floor(requestTypes.length / 2)}
              />
            )}
          />
        ) : (
          <FlatList
            key="horizontal"
            data={requestTypes}
            numColumns={4}
            renderItem={({ item, index }) => (
              <Item
                requestType={item}
                isHorizontal
                isLast={index + 1 > 4 * Math.floor(requestTypes.length / 4)}
              />
            )}
          />
        )}
      </View>

      <View className="px-4 py-3 flex flex-row justify-between bg-[#FFFFFB] w-full items-center">
        <Text className="font-bold text-xl text-black">{name}</Text>
        {/* <Dropdown
          data={languages}
          labelField="key"
          valueField="value"
          value={selectedLanguage}
          onChange={(e) => setSelectedLanguage(e.value)}
          renderLeftIcon={() => (
            <Image
              className="w-4 h-4 mr-2"
              source={require("../assets/globe.png")}
            />
          )}
          style={{
            height: 40,
            borderColor: "gray",
            borderWidth: 0.5,
            borderRadius: 8,
            paddingHorizontal: 8,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          // className="flex-1"
        /> */}
      </View>

      <Modal
        visible={isOptionModalVisible}
        animationType="slide"
        transparent
        onRequestClose={() => setIsOptionModalVisible(false)}
        statusBarTranslucent
      >
        <View className="flex-1 justify-center items-center align-middle">
          <View className="bg-blue-50 border-2 p-[20px] w-[85%] h-[80%] align-middle justify-between items-center rounded-[10px]">
            <View className="w-full items-center">
              <Text className="text-black pb-2 text-[20px]">
                {`Specify your ${
                  selectedRequest.name !== "Misc Assistance"
                    ? selectedRequest.name?.toLowerCase()
                    : "misc."
                } request`}
              </Text>
              {selectedRequest.options?.length > 0 &&
                selectedRequest.options.map((opt) => (
                  <Pressable
                    onPress={() => {
                      handleConfirm({
                        option: opt,
                        requestType: selectedRequest,
                      });
                    }}
                    className="bg-white rounded-[10px] p-4 m-2 w-4/5"
                  >
                    <Text>{opt}</Text>
                  </Pressable>
                ))}
            </View>
            {selectedRequest.name === "Misc Assistance" && (
              <TextInput
                className="border-2 w-full border-gray-300 rounded-md p-2 mt-5 justify-center text-gray-500"
                placeholder={t("Enter additional details")}
                onChangeText={(text) => setDetails(text)}
                value={details}
                multiline
                numberOfLines={30}
              />
            )}
            <View className="justify-center items-center pt-10">
              <View className="flex-row space-x-20">
                <TouchableOpacity
                  className="items-center rounded-md"
                  onPress={async () => {
                    setIsOptionModalVisible(false);
                    await getRequests();
                  }}
                >
                  <Image
                    className="w-[40px] h-[40px]"
                    source={require("../assets/cancel.png")}
                  />
                </TouchableOpacity>
                {selectedRequest.name === "Misc Assistance" && (
                  <TouchableOpacity
                    className="items-center rounded-md"
                    onPress={() =>
                      handleConfirm({ requestType: selectedRequest })
                    }
                  >
                    <Image
                      className="w-[40px] h-[40px]"
                      source={require("../assets/checked.png")}
                    />
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        animationType="slide"
        visible={closeAppModalVisible}
        transparent={true}
      >
        <View className="flex-1 justify-center items-center align-middle">
          <View className="bg-blue-50 border-2 p-[20px] w-[85%] h-[80%] align-middle items-center justify-center rounded-[10px]">
            <Text className="text-black pb-20 text-[20px] text-center">
              {t("You have been discharged. Please close the tab.")}
            </Text>
          </View>
        </View>
      </Modal>
    </SafeAreaView>
  );
};

export default RequestsPage;
