import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import fr from "./fr.json";
import ar from "./ar.json";
import cn from "./cn.json";
import kr from "./kr.json";

const resources = {
  en: en,
  fr: fr,
  ar: ar,
  cn: cn,
  kr: kr,
  // si: si,
};

i18n

  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    compatibilityJSON: "v3",
    resources,
    lng: "en", // default language to use.
  });

export default { i18n };

export const languages = [
  { key: "English", value: "en" },
  { key: "Français", value: "fr" },
  { key: "عربي", value: "ar" },
  { key: "中文", value: "cn" },
  { key: "한국어", value: "kr" },
];
