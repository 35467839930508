import React, { useEffect } from "react";
import { StatusBar, Text, TouchableOpacity, View } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../utils/types";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { fetchDoctorInformation, fetchRoomList } from "../utils/services";

type Props = { navigation: StackNavigationProp<RootStackParamList, "Login"> };

const LoginPage = ({ navigation }: Props) => {
  useEffect(() => {
    const checkDoctorId = async () => {
      const cachedDoctorId = await AsyncStorage.getItem("doctorId");
      const urlDoctorId = new URLSearchParams(window?.location.search).get(
        "doctorId"
      );
      const doctorId = cachedDoctorId ?? urlDoctorId;

      // if (!doctorId) {
      try {
        // const doctor = await fetchDoctorInformation(doctorId);
        const doctor = { name: "Dr. Bashir" };
        const roomList = await fetchRoomList(doctorId);
        navigation.navigate("Rooms", { doctor, roomList });
      } catch (e) {
        console.warn(e);
      }
      // }
    };

    checkDoctorId();
  }, []);

  return (
    <View
      className={`flex-1 pt-[${StatusBar.currentHeight}px] bg-[#ecf0f1] p-[10px]`}
    >
      <Text className="mt-[30px] text-[28px] font-bold text-center">
        Scan QR Code
      </Text>
      <Text className="m-[15px] pb-[10px] font-normal text-[18px] text-center">
        Please scan the QR code provided at your clinic to connect to your
        services
      </Text>
      <TouchableOpacity
        className="rounded-[10px] mt-[16px] py-[8px] border-[2px] border-[#20232a] bg-white w-[80%] self-center"
        onPress={() => navigation.navigate("QRCodeScanner")}
      >
        <Text className="text-center text-[20px] font-semibold">
          Click to Scan QR Code
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default LoginPage;
