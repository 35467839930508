import { DoctorRequest, Room } from "./types";

// const BASE_URL = "http://localhost:3000/api";
const BASE_URL = "https://demo.physiciancompanion.com/api";

// const BASE_URL = "https://amg.physiciancompanion.com/api";

export const fetchRequests = async (doctorId: string) => {
  // fetch patient requests from api
  // const response = await fetch(`${BASE_URL}/request?doctorId=${doctorId}`, {
  const response = await fetch(`${BASE_URL}/doctor/Bashir/request`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export const fetchRequestTypes = async (doctorId: string) => {
  // const response = await fetch(`${BASE_URL}/requestType/${doctorId}`, {
  //   method: "GET",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // });
  // const data = await response.json();
  const data = [
    {
      name: "Misc Assistance",
      priority: 6,
      color: "#cfe5ff",
      icon: "https://patientcompanion.s3.amazonaws.com/misc.png",
    },
    {
      name: "Update Record",
      priority: 7,
      color: "#cfe5ff",
      icon: "https://patientcompanion.s3.amazonaws.com/record.png",
    },
    {
      name: "Troubleshoot",
      priority: 8,
      color: "#cfe5ff",
      icon: "https://patientcompanion.s3.amazonaws.com/trouble.png",
    },
    {
      name: "Clean",
      priority: 4,
      color: "#feb058",
      icon: "https://patientcompanion.s3.amazonaws.com/clean.png",
    },
    {
      name: "Forms",
      priority: 5,
      color: "#cfe5ff",
      icon: "https://patientcompanion.s3.amazonaws.com/forms.png",
    },
    {
      name: "New Patient",
      priority: 3,
      color: "#ff7452",
      icon: "https://patientcompanion.s3.amazonaws.com/next.png",
    },
    {
      name: "Equipment",
      priority: 2,
      color: "#0fc3ff",
      icon: "https://patientcompanion.s3.amazonaws.com/equipment.png",
    },
    {
      name: "Setup",
      priority: 1,
      color: "#8accff",
      icon: "https://patientcompanion.s3.amazonaws.com/setup.png",
    },
  ];
  return data.sort((a: any, b: any) => a.priority - b.priority);
};

export const createRequest = async ({
  // doctorId,
  room,
  doctor,
  requestType,
  detail,
  // color,
  icon,
}) => {
  const colors = {
    "101": "#cfe5ff",
    "202": "#43c59e",
    "303": "#f7a072",
    "404": "#d66ba0",
  };
  console.log(
    "createRequest",
    room,
    doctor,
    requestType,
    detail,
    colors[room],
    icon
  );
  const response = await fetch(`${BASE_URL}/request`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      room,
      doctor: "Dr. Bashir",
      requestType,
      detail,
      icon,
      color: colors[room],
    }),
  });
  return response;
};

export const deleteRequest = async (requestId: string) => {
  const response = await fetch(`${BASE_URL}/request/${requestId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      deletedByDoctor: true,
    }),
  });
  const data = await response.json();
  return data;
};

export const fetchRoomList = async (doctorId: string) => {
  // const response = await fetch(`${BASE_URL}/room/${doctorId}`, {
  //   method: "GET",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // });
  // const data = await response.json();
  const data = [
    { name: "101" },
    { name: "202" },
    { name: "303" },
    { name: "404" },
  ];

  return data.sort((a: Room, b: Room) => a.name.localeCompare(b.name));
};

export const fetchDoctorInformation = async (doctorId: string) => {
  const response = await fetch(`${BASE_URL}/doctor/${doctorId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export const fetchEquipmentList = async (doctorId: string) => {
  const response = await fetch(`${BASE_URL}/equipment/${doctorId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export const fetchBedInformation = async (bedId: string) => {
  const response = await fetch(`${BASE_URL}/bed?id=${bedId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export const createPatient = async (room: string, bed: string) => {
  const response = await fetch(`${BASE_URL}/patient`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      room,
      bed,
    }),
  });
  const data = await response.json();
  return data.id;
};
