import React, { useCallback, useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  SafeAreaView,
} from "react-native";
import { RouteProp } from "@react-navigation/native";

import {
  BoxItemProps,
  DoctorRequest,
  ItemType,
  RequestType,
  RootStackParamList,
} from "../utils/types";
import { fetchRequestTypes, fetchRequests } from "../utils/services";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Dropdown } from "react-native-element-dropdown";
import { StackNavigationProp } from "@react-navigation/stack";
import { languages } from "../lang/i18n";

type Props = {
  route: RouteProp<RootStackParamList, "Rooms">;
  navigation: StackNavigationProp<RootStackParamList, "Rooms">;
};

const RoomsPage = ({ route, navigation }: Props) => {
  const { doctor, roomList } = route.params;
  const { name, id: doctorId } = doctor;

  const { t } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState("en"); // Default to English
  const [requestTypes, setRequestTypes] = useState<RequestType[]>([]);
  const [requests, setRequests] = useState<DoctorRequest[]>([]);

  useEffect(() => {
    i18next.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  useEffect(() => {
    fetchRequests(doctorId).then((data) => setRequests(data));
    fetchRequestTypes(doctorId).then((data) => {
      const reqTypes = data.map((rt) => {
        const options = rt.options ? rt.options.split(",") : [];
        return {
          ...rt,
          options: rt.options === "" ? null : options,
        };
      });

      setRequestTypes(reqTypes);
    });
  }, [doctorId]);

  return (
    <SafeAreaView
      className="bg-[#ADCDF6] max-w-screen"
      style={{ height: "100%", maxHeight: "100dvh" }}
    >
      <View className="flex flex-row justify-between items-center py-2 px-4 bg-[#FFFFFB]">
        <Image
          className="w-[35px] h-[35px]"
          source={require("../assets/logo.png")}
        />
        <Text className="text-black text-lg">Henry Medical Centre</Text>
      </View>

      <TouchableOpacity
        className="bg-[#FFFFFB] rounded-[25px] items-center justify-center border-2 border-black m-4"
        onPress={() => navigation.navigate("ViewRequests", { doctor })}
      >
        <Text className="text-black text-2xl lg:text-[30px] py-2">
          {t("View My Requests")}
        </Text>
      </TouchableOpacity>

      <View className="flex-1 overflow-y-scroll items-center">
        {roomList.map((room) => (
          <TouchableOpacity
            key={room.id}
            onPress={() =>
              navigation.navigate("Requests", {
                doctor,
                roomList,
                room,
                requests,
                requestTypes,
              })
            }
            className="flex-row justify-between bg-white w-[80%] p-4 m-2 border-2 rounded-[10px] border-gray-200 "
          >
            <Text className="text-lg">{room.name} </Text>
            {/* <Image
              source={require("../assets/right-arrow.png")}
              className="w-[20px] h-[20px] my-auto"
            /> */}
          </TouchableOpacity>
        ))}
      </View>

      <View className="px-4 py-3 flex flex-row justify-between bg-[#FFFFFB] w-full items-center">
        <Text className="font-bold text-xl text-black">{name}</Text>
        {/* <Dropdown
          data={languages}
          labelField="key"
          valueField="value"
          value={selectedLanguage}
          onChange={(e) => setSelectedLanguage(e.value)}
          renderLeftIcon={() => (
            <Image
              className="w-4 h-4 mr-2"
              source={require("../assets/globe.png")}
            />
          )}
          style={{
            height: 40,
            borderColor: "gray",
            borderWidth: 0.5,
            borderRadius: 8,
            paddingHorizontal: 8,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          // className="flex-1"
        /> */}
      </View>
    </SafeAreaView>
  );
};

export default RoomsPage;
